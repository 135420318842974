<template>
  <div class="">

  </div>
</template>

<script>
/* eslint-disable global-require */
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    onCheckVerifyCode() {
      const paramVerifyCode = this.$router.currentRoute.params.verifyCode;
      ApiService.get(`UserVerification/check-email-verify-code/${paramVerifyCode}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            AlertService.success(this, 'Your email address has been verified successfully.');
          } else {
            AlertService.warning(this, response.message);
          }
          this.$router.push({ name: 'dashboard' })
        });
    },
  },
  created() {
    this.onCheckVerifyCode();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
